var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "padding-block": "16px",
      "box-shadow": "0px 4px 8px rgba(0, 0, 0, 0.1)",
      "position": "sticky",
      "top": "84px",
      "background-color": "white",
      "z-index": "10"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "flex-shrink": "0",
      "width": "330px",
      "padding-inline": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "height": ['34px', '50px'],
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600",
      "margin-right": "auto"
    }
  }, [_vm._v(" Panduan Porsi ")])], 1), _c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "justify-content": "flex-start",
      "height": "auto",
      "padding-block": "8px",
      "overflow-x": "auto",
      "grid-gap": "8px"
    }
  }, _vm._l(_vm.optionsPanduanPorsi, function (option) {
    var _vm$selectedPanduanPo, _vm$selectedPanduanPo2, _vm$selectedPanduanPo3, _vm$selectedPanduanPo4, _vm$selectedPanduanPo5;

    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&:disabled': {
            backgroundColor: 'neutral.superLightGray',
            borderColor: 'neutral.888888',
            color: 'neutral.888888'
          }
        },
        expression: "{\n            '&:disabled': {\n              backgroundColor: 'neutral.superLightGray',\n              borderColor: 'neutral.888888',\n              color: 'neutral.888888',\n            },\n          }"
      }],
      key: option.id,
      class: ((_vm$selectedPanduanPo = _vm.selectedPanduanPorsi) === null || _vm$selectedPanduanPo === void 0 ? void 0 : _vm$selectedPanduanPo.id) === (option === null || option === void 0 ? void 0 : option.id) ? 'active' : '',
      attrs: {
        "type": "button",
        "h": ['30px', '40px'],
        "px": ['8px', '16px'],
        "m": "0",
        "font-size": ['12px', '16px'],
        "line-height": "24px",
        "rounded": "full",
        "color": ((_vm$selectedPanduanPo2 = _vm.selectedPanduanPorsi) === null || _vm$selectedPanduanPo2 === void 0 ? void 0 : _vm$selectedPanduanPo2.id) === (option === null || option === void 0 ? void 0 : option.id) ? 'white' : 'primary.400',
        "bg": ((_vm$selectedPanduanPo3 = _vm.selectedPanduanPorsi) === null || _vm$selectedPanduanPo3 === void 0 ? void 0 : _vm$selectedPanduanPo3.id) === (option === null || option === void 0 ? void 0 : option.id) ? '#007067' : 'white',
        "border-width": "1px",
        "border-style": "solid",
        "border-color": ((_vm$selectedPanduanPo4 = _vm.selectedPanduanPorsi) === null || _vm$selectedPanduanPo4 === void 0 ? void 0 : _vm$selectedPanduanPo4.id) === (option === null || option === void 0 ? void 0 : option.id) ? '#007067' : 'primary.400',
        "white-space": "nowrap",
        "font-weight": "400",
        "flex-shrink": "0",
        "disabled": option === null || option === void 0 ? void 0 : option.disabled,
        "_hover": {
          backgroundColor: ((_vm$selectedPanduanPo5 = _vm.selectedPanduanPorsi) === null || _vm$selectedPanduanPo5 === void 0 ? void 0 : _vm$selectedPanduanPo5.id) === (option === null || option === void 0 ? void 0 : option.id) ? '#007067' : 'primary.50'
        }
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          return _vm.onChangePanduanPorsi(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option === null || option === void 0 ? void 0 : option.label) + " ")]);
  }), 1)], 1), _c('c-divider', {
    attrs: {
      "orientation": "vertical",
      "border-color": "neutral.888888",
      "margin-right": "8px"
    }
  }), _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "width": "100%",
      "padding-right": "16px",
      "padding-left": "8px",
      "overflow": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0",
      "margin-right": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.menu-recommendation.detail'
        }));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600",
      "margin-right": "auto"
    }
  }, [_vm._v(" Rekomendasi Menu ")]), _c('c-tooltip', {
    attrs: {
      "label": _vm.iconCloud.text,
      "placement": "bottom"
    }
  }, [_c('c-box', {
    class: _vm.saveState === null ? 'animate-spin' : '',
    attrs: {
      "width": ['35px'],
      "height": ['35px'],
      "margin-right": "16px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCloud.icon,
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-save.svg'),
      "left-svg-icon-color": "white",
      "disabled": !_vm.isValidAllFormForAllDays || _vm.saveState === null || _vm.isLoadingSave || _vm.isSaveDraft === 1
    },
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(" Simpan ")])], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "gap": "0px",
      "margin-top": "0px"
    }
  }, [_c('ChipDays', {
    attrs: {
      "start-at": _vm.currentActiveDays[0],
      "end-at": _vm.currentActiveDays[_vm.currentActiveDays.length - 1],
      "selected-day": _vm.query.day,
      "invalid-day-validation": _vm.chipDaysState.invalidDayValidation,
      "disabled-day": _vm.isLoadingSave || _vm.saveState === null ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : _vm.chipDaysState.disabledDay,
      "dot-badge-days": _vm.chipDaysState.dotBadgeDay,
      "custom-item-width": "100%",
      "custom-item-min-width": "50px",
      "custom-template-columns": "repeat(10, 1fr)"
    },
    on: {
      "on-change-day": function onChangeDay(day) {
        _vm.$router.push(Object.assign({}, _vm.$route, {
          query: Object.assign({}, _vm.$route.query, {
            day: day
          })
        }));
      }
    }
  }), _c('c-flex', {
    attrs: {
      "gap": "8px",
      "flex-shrink": "0"
    }
  }, [_c('ModalMenuDuplicate', {
    attrs: {
      "is-valid-all-form": _vm.isValidAllForm ? true : false,
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null,
      "meal-time-states": _vm.listMealTimeStates
    },
    on: {
      "fetch-form-days": function fetchFormDays() {
        return _vm.getFormDays();
      }
    }
  }), _c('c-button', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "variant-color": "secondary",
      "color": "primary.400",
      "background-color": "secondary.400",
      "border-radius": "calc(infinity * 1px)",
      "size": "lg",
      "height": "50px",
      "disabled": _vm.isLoadingSave || _vm.saveState === null || !_vm.getIsEditableForm
    },
    on: {
      "click": function click() {
        if (_vm.isLoadingSave || _vm.saveState === null || !_vm.getIsEditableForm) { return; }
        _vm.dataModalCreateBatchMenuRecommendations.isOpen = true;
      }
    }
  }, [_c('c-box', {
    attrs: {
      "as": "span",
      "font-size": "30px",
      "font-weight": "400",
      "padding-right": "8px"
    }
  }, [_vm._v(" + ")]), _vm._v(" Sekaligus ")], 1)], 1)], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "padding-block": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "id": "container-portion-guide",
      "flex-direction": "column",
      "flex-shrink": "0",
      "width": "330px",
      "height": "100%",
      "max-height": "calc(100vh - 275px)",
      "padding-bottom": "calc(100vh - calc(100vh - 400px))",
      "overflow-y": "auto",
      "padding-left": "16px"
    }
  }, [_vm.previewMealPlanSchedule.length > 0 ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "16px"
    }
  }, _vm._l(_vm.previewMealPlanSchedule, function (mealPlanSchedule) {
    var _mealPlanSchedule$mea;

    return _c('c-box', {
      key: mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime,
      staticClass: "pulse",
      attrs: {
        "id": "portion-guide-".concat(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime)
      }
    }, [_c('MealTimeHeader', {
      attrs: {
        "meal-time": _vm.constants.mealPlanValueToLabel[mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime],
        "calories-amount": mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.caloriesAmount,
        "start-time": _vm.format24Time(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.startTime, 'HH:mm'),
        "end-time": _vm.format24Time(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.endTime, 'HH:mm')
      }
    }), (mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : (_mealPlanSchedule$mea = mealPlanSchedule.mealComposition) === null || _mealPlanSchedule$mea === void 0 ? void 0 : _mealPlanSchedule$mea.length) > 0 ? _c('c-accordion', {
      attrs: {
        "allow-toggle": true
      }
    }, _vm._l(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealComposition, function (item, itemIdx) {
      return _c('CardMealPortionGuide', {
        key: "".concat(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime, "-").concat(itemIdx),
        attrs: {
          "meal-type": item === null || item === void 0 ? void 0 : item.foodGroup,
          "meal-portion": "".concat((item === null || item === void 0 ? void 0 : item.portion) || '-'),
          "meal-portion-decimal": "".concat((item === null || item === void 0 ? void 0 : item.portionDecimal) || '0'),
          "meal-portion-unit": "Porsi",
          "meal-items": _vm.mergeMealItems(item === null || item === void 0 ? void 0 : item.portionDecimal, item === null || item === void 0 ? void 0 : item.mealItems, item === null || item === void 0 ? void 0 : item.additionalMealItems) || []
        }
      });
    }), 1) : _c('c-flex', {
      attrs: {
        "justify-content": "center",
        "align-items": "center",
        "width": "100%",
        "height": "100%"
      }
    }, [_c('NoData2', {
      attrs: {
        "text": "Tidak ada data ".concat(_vm.constants.mealPlanValueToLabel[mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime])
      }
    })], 1)], 1);
  }), 1) : _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('NoData2', {
    attrs: {
      "text": "Tidak ada data"
    }
  })], 1)], 1), _c('c-divider', {
    attrs: {
      "orientation": "vertical",
      "border-color": "neutral.888888",
      "margin-right": "8px"
    }
  }), _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "width": "100%",
      "max-height": "calc(100vh - 275px)",
      "overflow-y": "auto",
      "padding-right": "8px",
      "padding-left": "8px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "16px"
    }
  }, _vm._l(_vm.constants.steps, function (_ref, targetIdx) {
    var _vm$listMealTimeState, _vm$listMealTimeState2, _vm$constants$mealPla, _vm$previewMealPlanSc, _vm$previewMealPlanSc2, _vm$listMealTimeState3, _vm$listMealTimeState4, _vm$draftDuplicate, _vm$draftDuplicate$me, _vm$draftDuplicate$me2;

    var mealTime2 = _ref.value;
    return !((_vm$listMealTimeState = _vm.listMealTimeStates) !== null && _vm$listMealTimeState !== void 0 && (_vm$listMealTimeState2 = _vm$listMealTimeState[mealTime2]) !== null && _vm$listMealTimeState2 !== void 0 && _vm$listMealTimeState2.isSkipped) ? _c('c-flex', {
      key: targetIdx,
      attrs: {
        "gap": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "width": "238px",
        "flex-shrink": "0"
      }
    }, [_c('c-flex', {
      attrs: {
        "width": "100%",
        "justify-content": "space-between",
        "align-items": "center",
        "margin-bottom": "8px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "18px",
        "font-weight": "600",
        "color": "neutral.black"
      }
    }, [_vm._v(" " + _vm._s(((_vm$constants$mealPla = _vm.constants.mealPlanValueToLabel) === null || _vm$constants$mealPla === void 0 ? void 0 : _vm$constants$mealPla[mealTime2]) || '-') + " ")]), _c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "500",
        "color": "neutral.black"
      }
    }, [_vm._v(" " + _vm._s(((_vm$previewMealPlanSc = _vm.previewMealPlanSchedule) === null || _vm$previewMealPlanSc === void 0 ? void 0 : (_vm$previewMealPlanSc2 = _vm$previewMealPlanSc.find(function (el) {
      return (el === null || el === void 0 ? void 0 : el.mealTime) === mealTime2;
    })) === null || _vm$previewMealPlanSc2 === void 0 ? void 0 : _vm$previewMealPlanSc2.caloriesAmount) || '-') + "kkal ")])], 1), _c('PanelPreviewMenuRecommendation', {
      attrs: {
        "id-program": _vm.$route.params.programId,
        "id-client": _vm.$route.params.clientId,
        "month": _vm.$route.params.month,
        "meal-time": mealTime2,
        "day": _vm.query.day,
        "total-days": 10,
        "refetch": _vm.saveState
      }
    })], 1), _c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "flex-direction": "column",
        "justify-content": "space-between"
      }
    }, [(_vm$listMealTimeState3 = _vm.listMealTimeStates) !== null && _vm$listMealTimeState3 !== void 0 && (_vm$listMealTimeState4 = _vm$listMealTimeState3[mealTime2]) !== null && _vm$listMealTimeState4 !== void 0 && _vm$listMealTimeState4.isSkipped ? _c('c-box', {
      attrs: {
        "margin-bottom": "16px"
      }
    }, [_c('Info2', {
      attrs: {
        "variant": "warning",
        "text": "Kamu tidak bisa mengisi Rekomendasi menu karena memilih tidak makan pada waktu makan ini"
      }
    })], 1) : !_vm.isValidTabNutritionalProfile || !_vm.isValidTabMealPlan ? _c('c-box', {
      attrs: {
        "margin-bottom": "16px"
      }
    }, [_c('Info2', {
      attrs: {
        "variant": "danger",
        "text": "Kamu tidak bisa mengisi Rekomendasi Menu sebelum mengisi Rencana makan"
      }
    })], 1) : _vm._e(), _c('c-box', [((_vm$draftDuplicate = _vm.draftDuplicate) === null || _vm$draftDuplicate === void 0 ? void 0 : (_vm$draftDuplicate$me = _vm$draftDuplicate[mealTime2]) === null || _vm$draftDuplicate$me === void 0 ? void 0 : (_vm$draftDuplicate$me2 = _vm$draftDuplicate$me.duplicationDetail) === null || _vm$draftDuplicate$me2 === void 0 ? void 0 : _vm$draftDuplicate$me2.isEdited) === 0 ? _c('c-flex', {
      attrs: {
        "align-items": "center",
        "justify-content": "space-between",
        "margin-bottom": "16px"
      }
    }, [_c('c-button', {
      attrs: {
        "variant": "solid",
        "variant-color": "primary",
        "border-radius": "30px",
        "font-weight": "500",
        "font-size": "18px"
      },
      on: {
        "click": function click($event) {
          return _vm.onOpenEditMenuDuplicate(mealTime2);
        }
      }
    }, [_c('c-box', {
      attrs: {
        "as": "span",
        "min-height": ['20px', '22px'],
        "min-width": ['20px', '22px'],
        "height": ['20px', '22px'],
        "width": ['20px', '22px'],
        "margin-right": "8px"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-edit-2.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#FFF"
      }
    })], 1), _vm._v(" Edit Menu Duplikasi ")], 1)], 1) : _vm._e(), _c('TestFieldArray', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var _vm$draftDuplicate15, _vm$draftDuplicate15$, _vm$draftDuplicate15$2;

          var push = _ref2.push,
              remove = _ref2.remove;
          return [_vm._l(_vm.targets[mealTime2], function (v, i) {
            var _vm$draftDuplicate2, _vm$draftDuplicate2$m, _vm$draftDuplicate2$m2, _vm$draftDuplicate3, _vm$draftDuplicate3$m, _vm$draftDuplicate3$m2, _vm$draftDuplicate4, _vm$draftDuplicate4$m, _vm$draftDuplicate4$m2, _vm$draftDuplicate5, _vm$draftDuplicate5$m, _vm$draftDuplicate5$m2, _vm$draftDuplicate6, _vm$draftDuplicate6$m, _vm$draftDuplicate6$m2, _vm$targets, _vm$targets$mealTime, _vm$draftDuplicate14, _vm$draftDuplicate14$, _vm$draftDuplicate14$2;

            return _c('c-flex', {
              key: i + 'a',
              attrs: {
                "margin-top": "14px",
                "_first": {
                  marginTop: '4px'
                },
                "gap": "8px"
              }
            }, [_c('c-box', {
              attrs: {
                "width": "110px",
                "flex-shrink": "0"
              }
            }, [_c('FormSelect', {
              attrs: {
                "is-without-margin-bottom": "",
                "value": v.menuType,
                "label": "",
                "placeholder": "Pilih Jenis Menu",
                "is-required": null,
                "is-without-label": "",
                "size": "md",
                "options": [{
                  value: 'singleEntry',
                  label: 'Single Entry'
                }, {
                  value: 'multipleEntry',
                  label: 'Multiple Entry'
                }],
                "is-disabled": ((_vm$draftDuplicate2 = _vm.draftDuplicate) === null || _vm$draftDuplicate2 === void 0 ? void 0 : (_vm$draftDuplicate2$m = _vm$draftDuplicate2[mealTime2]) === null || _vm$draftDuplicate2$m === void 0 ? void 0 : (_vm$draftDuplicate2$m2 = _vm$draftDuplicate2$m.duplicationDetail) === null || _vm$draftDuplicate2$m2 === void 0 ? void 0 : _vm$draftDuplicate2$m2.isEdited) == 0 || !_vm.getIsEditableForm,
                "is-invalid": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menuType)
              },
              on: {
                "input": function input(val) {
                  v.menuType = val;
                  v.menuName = null;
                  v.menusArray = [{
                    _menuType: val,
                    foodName: null,
                    servingSize: null,
                    servingSizeUnit: null,
                    servingSizeUnitOther: null,
                    servingSizeOther: null,
                    servingSizeOtherUnit: null,
                    photoUrl: null,
                    mealGlossariesId: null
                  }];
                  v.menusObject = {
                    _menuType: val,
                    servingSize: null,
                    servingSizeUnit: null,
                    servingSizeUnitOther: null,
                    servingSizeOther: null,
                    servingSizeOtherUnit: null,
                    photoUrl: null,
                    mealGlossariesId: null
                  };
                },
                "blur": _vm.$v.targets[mealTime2].$each[i].menuType.$touch
              }
            })], 1), v.menuType === 'singleEntry' || !v.menuType ? _c('c-flex', {
              attrs: {
                "align-items": "flex-start",
                "gap": "8px",
                "width": "100%"
              }
            }, [_c('c-box', {
              attrs: {
                "min-width": "126px",
                "width": "100%"
              }
            }, [_c('InputAutocomplete', {
              attrs: {
                "size": "md",
                "type": "meal-glossary",
                "label": "",
                "is-without-label": "",
                "is-without-margin-bottom": "",
                "placeholder": "Nama Menu",
                "is-required": null,
                "is-disabled": !v.menuType || ((_vm$draftDuplicate3 = _vm.draftDuplicate) === null || _vm$draftDuplicate3 === void 0 ? void 0 : (_vm$draftDuplicate3$m = _vm$draftDuplicate3[mealTime2]) === null || _vm$draftDuplicate3$m === void 0 ? void 0 : (_vm$draftDuplicate3$m2 = _vm$draftDuplicate3$m.duplicationDetail) === null || _vm$draftDuplicate3$m2 === void 0 ? void 0 : _vm$draftDuplicate3$m2.isEdited) == 0 || !_vm.getIsEditableForm,
                "is-invalid": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menuName),
                "with-border": true,
                "show-empty-value": false
              },
              on: {
                "blur": _vm.$v.targets[mealTime2].$each[i].menuName.$touch
              },
              model: {
                value: v.menuName,
                callback: function callback($$v) {
                  _vm.$set(v, "menuName", $$v);
                },
                expression: "v.menuName"
              }
            })], 1), _c('c-box', [_c('InputTextWithSelect', {
              attrs: {
                "size": "md",
                "label": "",
                "custom-helper-text-select-other": "",
                "input-placeholder": "URT",
                "select-placeholder": "Pilih",
                "input-value": v.menusObject.servingSize,
                "is-invalid-input": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusObject.servingSize),
                "select-value": v.menusObject.servingSizeUnit,
                "is-invalid-select": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeUnit),
                "select-other-value": v.menusObject.servingSizeUnitOther,
                "is-invalid-select-other": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeUnitOther),
                "select-options": _vm.optionPortionUnits,
                "is-disabled": !v.menuType || ((_vm$draftDuplicate4 = _vm.draftDuplicate) === null || _vm$draftDuplicate4 === void 0 ? void 0 : (_vm$draftDuplicate4$m = _vm$draftDuplicate4[mealTime2]) === null || _vm$draftDuplicate4$m === void 0 ? void 0 : (_vm$draftDuplicate4$m2 = _vm$draftDuplicate4$m.duplicationDetail) === null || _vm$draftDuplicate4$m2 === void 0 ? void 0 : _vm$draftDuplicate4$m2.isEdited) == 0 || !_vm.getIsEditableForm,
                "is-without-margin-bottom": "",
                "custom-width-select": "90px",
                "custom-width-input": "60px",
                "is-without-label": ""
              },
              on: {
                "blur:input": _vm.$v.targets[mealTime2].$each[i].menusObject.servingSize.$touch,
                "blur:select": _vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeUnit.$touch,
                "blur:select-other": _vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeUnitOther.$touch,
                "change:input": function changeInput(v_) {
                  return v.menusObject.servingSize = v_;
                },
                "change:select": function changeSelect(v_) {
                  return v.menusObject.servingSizeUnit = v_;
                },
                "change:select-other": function changeSelectOther(v_) {
                  return v.menusObject.servingSizeUnitOther = v_;
                }
              }
            })], 1), _c('c-box', {
              attrs: {
                "width": "150px"
              }
            }, [_c('InputTextWithSelect', {
              attrs: {
                "size": "md",
                "label": "",
                "custom-helper-text-select-other": "",
                "input-placeholder": "Ukuran Lainnya",
                "select-placeholder": "Pilih",
                "input-value": v.menusObject.servingSizeOther,
                "is-invalid-input": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeOther),
                "select-value": v.menusObject.servingSizeOtherUnit,
                "is-invalid-select": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeOtherUnit),
                "select-options": _vm.getOptionServingSizeOtherUnits,
                "is-disabled": !v.menuType || ((_vm$draftDuplicate5 = _vm.draftDuplicate) === null || _vm$draftDuplicate5 === void 0 ? void 0 : (_vm$draftDuplicate5$m = _vm$draftDuplicate5[mealTime2]) === null || _vm$draftDuplicate5$m === void 0 ? void 0 : (_vm$draftDuplicate5$m2 = _vm$draftDuplicate5$m.duplicationDetail) === null || _vm$draftDuplicate5$m2 === void 0 ? void 0 : _vm$draftDuplicate5$m2.isEdited) == 0 || !_vm.getIsEditableForm,
                "is-without-margin-bottom": "",
                "custom-width-select": "90px",
                "custom-width-input": "60px",
                "is-without-label": ""
              },
              on: {
                "blur:input": _vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeOther.$touch,
                "blur:select": _vm.$v.targets[mealTime2].$each[i].menusObject.servingSizeOtherUnit.$touch,
                "change:input": function changeInput(v_) {
                  return v.menusObject.servingSizeOther = v_;
                },
                "change:select": function changeSelect(v_) {
                  return v.menusObject.servingSizeOtherUnit = v_;
                }
              }
            })], 1), _c('c-box', [_c('InputImage2', {
              attrs: {
                "custom-style": {
                  width: ['46px', '50px'],
                  height: ['46px', '50px']
                },
                "with-bottom-text": false,
                "photo-url": v.menusObject.photoUrl,
                "is-disabled": !v.menuType || ((_vm$draftDuplicate6 = _vm.draftDuplicate) === null || _vm$draftDuplicate6 === void 0 ? void 0 : (_vm$draftDuplicate6$m = _vm$draftDuplicate6[mealTime2]) === null || _vm$draftDuplicate6$m === void 0 ? void 0 : (_vm$draftDuplicate6$m2 = _vm$draftDuplicate6$m.duplicationDetail) === null || _vm$draftDuplicate6$m2 === void 0 ? void 0 : _vm$draftDuplicate6$m2.isEdited) == 0 || !_vm.getIsEditableForm
              },
              on: {
                "delete:photo": function deletePhoto() {
                  v.menusObject.photoUrl = null;
                  v.menusObject.mealGlossariesId = null;
                },
                "click:photo": function clickPhoto() {
                  _vm.popupIsOpen = true;
                  _vm.popupMealTime = mealTime2;
                  _vm.popupItemIndexes = [i];
                }
              }
            })], 1)], 1) : v.menuType === 'multipleEntry' ? _c('TestFieldArray', {
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref3) {
                  var _vm$draftDuplicate7, _vm$draftDuplicate7$m, _vm$draftDuplicate7$m2, _vm$draftDuplicate13, _vm$draftDuplicate13$, _vm$draftDuplicate13$2;

                  var push2 = _ref3.push,
                      remove2 = _ref3.remove;
                  return [_c('c-flex', {
                    attrs: {
                      "gap": "8px"
                    }
                  }, [_c('c-box', {
                    attrs: {
                      "min-width": "115px"
                    }
                  }, [_c('InputAutocomplete', {
                    attrs: {
                      "size": "md",
                      "type": "meal-glossary",
                      "label": "",
                      "placeholder": "Nama Menu",
                      "is-without-label": "",
                      "is-without-margin-bottom": "",
                      "is-required": null,
                      "is-disabled": !v.menuType || ((_vm$draftDuplicate7 = _vm.draftDuplicate) === null || _vm$draftDuplicate7 === void 0 ? void 0 : (_vm$draftDuplicate7$m = _vm$draftDuplicate7[mealTime2]) === null || _vm$draftDuplicate7$m === void 0 ? void 0 : (_vm$draftDuplicate7$m2 = _vm$draftDuplicate7$m.duplicationDetail) === null || _vm$draftDuplicate7$m2 === void 0 ? void 0 : _vm$draftDuplicate7$m2.isEdited) == 0 || !_vm.getIsEditableForm,
                      "is-invalid": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menuName),
                      "with-border": true,
                      "show-empty-value": false
                    },
                    on: {
                      "blur": _vm.$v.targets[mealTime2].$each[i].menuName.$touch
                    },
                    model: {
                      value: v.menuName,
                      callback: function callback($$v) {
                        _vm.$set(v, "menuName", $$v);
                      },
                      expression: "v.menuName"
                    }
                  })], 1), _c('c-box', _vm._l(v.menusArray, function (v2, i2) {
                    var _vm$draftDuplicate8, _vm$draftDuplicate8$m, _vm$draftDuplicate8$m2, _vm$draftDuplicate9, _vm$draftDuplicate9$m, _vm$draftDuplicate9$m2, _vm$draftDuplicate10, _vm$draftDuplicate10$, _vm$draftDuplicate10$2, _vm$draftDuplicate11, _vm$draftDuplicate11$, _vm$draftDuplicate11$2, _vm$draftDuplicate12, _vm$draftDuplicate12$, _vm$draftDuplicate12$2;

                    return _c('c-flex', {
                      key: i2,
                      attrs: {
                        "gap": "8px",
                        "position": "relative",
                        "margin-top": "14px",
                        "_first": {
                          marginTop: '0px'
                        }
                      }
                    }, [true ? _c('c-flex', {
                      attrs: {
                        "gap": "8px",
                        "width": "100%",
                        "align-items": "flex-start"
                      }
                    }, [_c('c-box', {
                      attrs: {
                        "width": "100%",
                        "min-width": "115px"
                      }
                    }, [_c('InputAutocomplete', {
                      attrs: {
                        "size": "md",
                        "type": "meal-glossary",
                        "is-without-label": "",
                        "is-without-margin-bottom": "",
                        "is-required": null,
                        "label": "",
                        "placeholder": "Komposisi",
                        "is-disabled": !v.menuType || ((_vm$draftDuplicate8 = _vm.draftDuplicate) === null || _vm$draftDuplicate8 === void 0 ? void 0 : (_vm$draftDuplicate8$m = _vm$draftDuplicate8[mealTime2]) === null || _vm$draftDuplicate8$m === void 0 ? void 0 : (_vm$draftDuplicate8$m2 = _vm$draftDuplicate8$m.duplicationDetail) === null || _vm$draftDuplicate8$m2 === void 0 ? void 0 : _vm$draftDuplicate8$m2.isEdited) == 0 || !_vm.getIsEditableForm,
                        "is-invalid": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].foodName),
                        "with-border": true,
                        "show-empty-value": false
                      },
                      on: {
                        "blur": _vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].foodName.$touch
                      },
                      model: {
                        value: v2.foodName,
                        callback: function callback($$v) {
                          _vm.$set(v2, "foodName", $$v);
                        },
                        expression: "v2.foodName"
                      }
                    })], 1), _c('c-box', [_c('InputTextWithSelect', {
                      attrs: {
                        "size": "md",
                        "is-without-label": "",
                        "is-without-margin-bottom": "",
                        "is-required": null,
                        "label": "",
                        "custom-helper-text-select-other": "",
                        "input-placeholder": "URT",
                        "select-placeholder": "Pilih",
                        "input-value": v2.servingSize,
                        "is-invalid-input": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSize),
                        "select-value": v2.servingSizeUnit,
                        "is-invalid-select": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeUnit),
                        "select-other-value": v2.servingSizeUnitOther,
                        "is-invalid-select-other": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeUnitOther),
                        "select-options": _vm.optionPortionUnits,
                        "is-disabled": !v.menuType || ((_vm$draftDuplicate9 = _vm.draftDuplicate) === null || _vm$draftDuplicate9 === void 0 ? void 0 : (_vm$draftDuplicate9$m = _vm$draftDuplicate9[mealTime2]) === null || _vm$draftDuplicate9$m === void 0 ? void 0 : (_vm$draftDuplicate9$m2 = _vm$draftDuplicate9$m.duplicationDetail) === null || _vm$draftDuplicate9$m2 === void 0 ? void 0 : _vm$draftDuplicate9$m2.isEdited) == 0 || !_vm.getIsEditableForm,
                        "custom-width-select": "90px",
                        "custom-width-input": "60px"
                      },
                      on: {
                        "blur:input": _vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSize.$touch,
                        "blur:select": function blurSelect($event) {
                          _vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeUnit.$touch;
                        },
                        "blur:select-other": function blurSelectOther($event) {
                          _vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeUnitOther.$touch;
                        },
                        "change:input": function changeInput(v_) {
                          return v2.servingSize = v_;
                        },
                        "change:select": function changeSelect(v_) {
                          return v2.servingSizeUnit = v_;
                        },
                        "change:select-other": function changeSelectOther(v_) {
                          return v2.servingSizeUnitOther = v_;
                        }
                      }
                    })], 1), _c('c-box', {
                      attrs: {
                        "width": "150px"
                      }
                    }, [_c('InputTextWithSelect', {
                      attrs: {
                        "size": "md",
                        "is-without-label": "",
                        "is-without-margin-bottom": "",
                        "is-required": null,
                        "label": "",
                        "custom-helper-text-select-other": "",
                        "input-placeholder": "Ukuran Lainnya",
                        "select-placeholder": "Pilih",
                        "input-value": v2.servingSizeOther,
                        "is-invalid-input": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOther),
                        "select-value": v2.servingSizeOtherUnit,
                        "is-invalid-select": _vm.isInvalidField(_vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOtherUnit),
                        "select-options": _vm.getOptionServingSizeOtherUnits,
                        "is-disabled": !v.menuType || ((_vm$draftDuplicate10 = _vm.draftDuplicate) === null || _vm$draftDuplicate10 === void 0 ? void 0 : (_vm$draftDuplicate10$ = _vm$draftDuplicate10[mealTime2]) === null || _vm$draftDuplicate10$ === void 0 ? void 0 : (_vm$draftDuplicate10$2 = _vm$draftDuplicate10$.duplicationDetail) === null || _vm$draftDuplicate10$2 === void 0 ? void 0 : _vm$draftDuplicate10$2.isEdited) == 0 || !_vm.getIsEditableForm,
                        "custom-width-select": "90px",
                        "custom-width-input": "60px"
                      },
                      on: {
                        "blur:input": _vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOther.$touch,
                        "blur:select": function blurSelect($event) {
                          _vm.$v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOtherUnit.$touch;
                        },
                        "change:input": function changeInput(v_) {
                          return v2.servingSizeOther = v_;
                        },
                        "change:select": function changeSelect(v_) {
                          return v2.servingSizeOtherUnit = v_;
                        }
                      }
                    })], 1), _c('c-box', [_c('InputImage2', {
                      attrs: {
                        "custom-style": {
                          width: ['46px', '50px'],
                          height: ['46px', '50px']
                        },
                        "with-bottom-text": false,
                        "photo-url": v2.photoUrl,
                        "is-disabled": !v.menuType || ((_vm$draftDuplicate11 = _vm.draftDuplicate) === null || _vm$draftDuplicate11 === void 0 ? void 0 : (_vm$draftDuplicate11$ = _vm$draftDuplicate11[mealTime2]) === null || _vm$draftDuplicate11$ === void 0 ? void 0 : (_vm$draftDuplicate11$2 = _vm$draftDuplicate11$.duplicationDetail) === null || _vm$draftDuplicate11$2 === void 0 ? void 0 : _vm$draftDuplicate11$2.isEdited) == 0 || !_vm.getIsEditableForm
                      },
                      on: {
                        "delete:photo": function deletePhoto() {
                          v2.photoUrl = null;
                          v2.mealGlossariesId = null;
                        },
                        "click:photo": function clickPhoto() {
                          _vm.popupIsOpen = true;
                          _vm.popupMealTime = mealTime2;
                          _vm.popupItemIndexes = [i, i2];
                        }
                      }
                    })], 1)], 1) : _vm._e(), _c('c-flex', {
                      attrs: {
                        "align-items": "center",
                        "height": ['46px', '50px'],
                        "position": "absolute",
                        "right": ['calc(0px - 8px - 36px)', 'calc(0px - 8px - 40px)']
                      }
                    }, [i2 !== 0 ? _c('c-button', {
                      attrs: {
                        "background-color": "transparent",
                        "_hover": {
                          backgroundColor: 'neutral.lightGray'
                        },
                        "margin-left": "auto",
                        "width": "40px",
                        "disabled": ((_vm$draftDuplicate12 = _vm.draftDuplicate) === null || _vm$draftDuplicate12 === void 0 ? void 0 : (_vm$draftDuplicate12$ = _vm$draftDuplicate12[mealTime2]) === null || _vm$draftDuplicate12$ === void 0 ? void 0 : (_vm$draftDuplicate12$2 = _vm$draftDuplicate12$.duplicationDetail) === null || _vm$draftDuplicate12$2 === void 0 ? void 0 : _vm$draftDuplicate12$2.isEdited) == 0 || !_vm.getIsEditableForm
                      },
                      on: {
                        "click": function click($event) {
                          return remove2(v.menusArray, i2);
                        }
                      }
                    }, [_c('c-box', {
                      attrs: {
                        "height": "25px",
                        "width": "25px"
                      }
                    }, [_c('inline-svg', {
                      attrs: {
                        "src": require('@/assets/icons/icon-trash.svg'),
                        "height": "25px",
                        "width": "25px",
                        "fill": "#D32737"
                      }
                    })], 1)], 1) : _vm._e()], 1)], 1);
                  }), 1)], 1), _c('c-flex', {
                    attrs: {
                      "justify-content": "flex-end",
                      "margin-top": "14px"
                    }
                  }, [_c('BaseButton', {
                    attrs: {
                      "size": "small",
                      "border-radius": "1000px",
                      "variant": "outlined",
                      "background-color": "white",
                      "disabled": !v.menuType || _vm.$v.targets[mealTime2].$invalid || ((_vm$draftDuplicate13 = _vm.draftDuplicate) === null || _vm$draftDuplicate13 === void 0 ? void 0 : (_vm$draftDuplicate13$ = _vm$draftDuplicate13[mealTime2]) === null || _vm$draftDuplicate13$ === void 0 ? void 0 : (_vm$draftDuplicate13$2 = _vm$draftDuplicate13$.duplicationDetail) === null || _vm$draftDuplicate13$2 === void 0 ? void 0 : _vm$draftDuplicate13$2.isEdited) == 0 || !_vm.getIsEditableForm,
                      "width": "100%",
                      "max-width": "415px",
                      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
                      "left-svg-icon-color": "#008C81"
                    },
                    on: {
                      "click": function click($event) {
                        $event.preventDefault();
                        return push2(_vm.targets[mealTime2][i].menusArray, {
                          _menuType: v.menuType,
                          foodName: null,
                          servingSize: null,
                          servingSizeUnit: null,
                          servingSizeUnitOther: null,
                          servingSizeOther: null,
                          servingSizeOtherUnit: null,
                          photoUrl: null,
                          mealGlossariesId: null
                        });
                      }
                    }
                  }, [_vm._v(" Tambah Komposisi Makanan ")])], 1)];
                }
              }], null, true)
            }) : _vm._e(), _c('c-flex', {
              attrs: {
                "align-items": "center",
                "height": ['46px', '50px'],
                "min-width": "50px"
              }
            }, [((_vm$targets = _vm.targets) === null || _vm$targets === void 0 ? void 0 : (_vm$targets$mealTime = _vm$targets[mealTime2]) === null || _vm$targets$mealTime === void 0 ? void 0 : _vm$targets$mealTime.length) >= 2 ? _c('c-button', {
              attrs: {
                "background-color": "transparent",
                "_hover": {
                  backgroundColor: 'neutral.lightGray'
                },
                "disabled": ((_vm$draftDuplicate14 = _vm.draftDuplicate) === null || _vm$draftDuplicate14 === void 0 ? void 0 : (_vm$draftDuplicate14$ = _vm$draftDuplicate14[mealTime2]) === null || _vm$draftDuplicate14$ === void 0 ? void 0 : (_vm$draftDuplicate14$2 = _vm$draftDuplicate14$.duplicationDetail) === null || _vm$draftDuplicate14$2 === void 0 ? void 0 : _vm$draftDuplicate14$2.isEdited) == 0 || !_vm.getIsEditableForm,
                "padding": "0"
              },
              on: {
                "click": function click($event) {
                  return remove(_vm.targets[mealTime2], i);
                }
              }
            }, [_c('c-box', {
              attrs: {
                "height": "25px",
                "width": "25px"
              }
            }, [_c('inline-svg', {
              attrs: {
                "src": require('@/assets/icons/icon-trash.svg'),
                "height": "25px",
                "width": "25px",
                "fill": "#D32737"
              }
            })], 1)], 1) : _vm._e()], 1)], 1);
          }), _c('c-flex', {
            attrs: {
              "justify-content": "center"
            }
          }, [_c('BaseButton', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                color: '#008C81 !important'
              },
              expression: "{\n                      color: '#008C81 !important',\n                    }"
            }],
            attrs: {
              "margin": "auto",
              "mt": "14px",
              "size": "small",
              "border-radius": "1000px",
              "width": "70%",
              "disabled": _vm.$v.targets[mealTime2].$invalid || ((_vm$draftDuplicate15 = _vm.draftDuplicate) === null || _vm$draftDuplicate15 === void 0 ? void 0 : (_vm$draftDuplicate15$ = _vm$draftDuplicate15[mealTime2]) === null || _vm$draftDuplicate15$ === void 0 ? void 0 : (_vm$draftDuplicate15$2 = _vm$draftDuplicate15$.duplicationDetail) === null || _vm$draftDuplicate15$2 === void 0 ? void 0 : _vm$draftDuplicate15$2.isEdited) == 0 || !_vm.getIsEditableForm,
              "color": "secondary",
              "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
              "left-svg-icon-color": "#008C81"
            },
            on: {
              "click": function click($event) {
                var _Date$now;

                $event.preventDefault();
                push(_vm.targets[mealTime2], {
                  id: (_Date$now = Date.now()) === null || _Date$now === void 0 ? void 0 : _Date$now.toString(),
                  menuType: null,
                  // single | multiple
                  menuName: null,
                  // {multipleEntry Only}
                  menusObject: {
                    // {object: singleEntry | array: multipleEntry}
                    servingSize: null,
                    servingSizeUnit: null,
                    photoUrl: null,
                    servingSizeUnitOther: null,
                    servingSizeOther: null,
                    servingSizeOtherUnit: null,
                    mealGlossariesId: null
                  },
                  menusArray: [{
                    foodName: null,
                    servingSize: null,
                    servingSizeUnit: null,
                    photoUrl: null,
                    servingSizeUnitOther: null,
                    servingSizeOther: null,
                    servingSizeOtherUnit: null,
                    mealGlossariesId: null
                  }]
                });
              }
            }
          }, [_vm._v(" Tambah Menu Lainnya ")])], 1)];
        }
      }], null, true)
    }), _c('c-box', {
      attrs: {
        "as": "hr",
        "margin-top": "16px"
      }
    })], 1)], 1)], 1) : _vm._e();
  }), 1)], 1)], 1), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData,
      "image": require('@/assets/images/success-image.svg'),
      "title": "Berhasil Menyimpan Data"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(Object.assign({}, _vm.$route, {
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: Object.assign({}, _vm.$route.params, {
                  bypass: 1
                })
              }));
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalFailedSaveData,
      "image": require('@/assets/images/image-warning.svg'),
      "title": "Gagal Menyimpan Data",
      "description": "Terdapat kesalahan data, coba tinjau ulang datamu"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFailedSaveData = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalCreateBatchMenuRecommendations', {
    attrs: {
      "client-id2": _vm.$route.params.clientId,
      "program-id2": _vm.$route.params.programId,
      "month": _vm.$route.params.month,
      "chip-days-state": {
        invalidDayValidation: [],
        // skip invalid state
        disabledDay: _vm.chipDaysState.disabledDay,
        mealtimeStates: _vm.listMealTimeStates
      },
      "option-portion-units": _vm.optionPortionUnits,
      "is-open": _vm.dataModalCreateBatchMenuRecommendations.isOpen,
      "selected-day-default-value": _vm.query.day
    },
    on: {
      "close": function close($event) {
        _vm.dataModalCreateBatchMenuRecommendations.isOpen = false;
      }
    }
  }), _c('ModalChooseMenu', {
    attrs: {
      "is-open": _vm.popupIsOpen,
      "title": "Pilih Tampilan",
      "is-loading-data-food": _vm.popupIsLoadingDataFood,
      "options": _vm.popupOptions,
      "selected-foods": _vm.popupSelectedMenu,
      "pages": _vm.popupPages,
      "filter": _vm.popupFilter
    },
    on: {
      "change:selected-food": function changeSelectedFood(menu) {
        var _vm$targets2, _vm$targets2$_vm$popu, _vm$targets2$_vm$popu2, _vm$targets2$_vm$popu3, _vm$popupItemIndexes, _vm$popupItemIndexes2, _vm$targets3, _vm$targets3$_vm$popu, _vm$targets3$_vm$popu2, _vm$popupItemIndexes3;

        if ((_vm$targets2 = _vm.targets) !== null && _vm$targets2 !== void 0 && (_vm$targets2$_vm$popu = _vm$targets2[_vm.popupMealTime]) !== null && _vm$targets2$_vm$popu !== void 0 && (_vm$targets2$_vm$popu2 = _vm$targets2$_vm$popu[(_vm$popupItemIndexes = _vm.popupItemIndexes) === null || _vm$popupItemIndexes === void 0 ? void 0 : _vm$popupItemIndexes[0]]) !== null && _vm$targets2$_vm$popu2 !== void 0 && (_vm$targets2$_vm$popu3 = _vm$targets2$_vm$popu2.menusArray) !== null && _vm$targets2$_vm$popu3 !== void 0 && _vm$targets2$_vm$popu3[(_vm$popupItemIndexes2 = _vm.popupItemIndexes) === null || _vm$popupItemIndexes2 === void 0 ? void 0 : _vm$popupItemIndexes2[1]]) {
          _vm.targets[_vm.popupMealTime][_vm.popupItemIndexes[0]].menusArray[_vm.popupItemIndexes[1]].photoUrl = (menu === null || menu === void 0 ? void 0 : menu.photoUrl) || '';
          _vm.targets[_vm.popupMealTime][_vm.popupItemIndexes[0]].menusArray[_vm.popupItemIndexes[1]].mealGlossariesId = (menu === null || menu === void 0 ? void 0 : menu.id) || '';
        } else if ((_vm$targets3 = _vm.targets) !== null && _vm$targets3 !== void 0 && (_vm$targets3$_vm$popu = _vm$targets3[_vm.popupMealTime]) !== null && _vm$targets3$_vm$popu !== void 0 && (_vm$targets3$_vm$popu2 = _vm$targets3$_vm$popu[(_vm$popupItemIndexes3 = _vm.popupItemIndexes) === null || _vm$popupItemIndexes3 === void 0 ? void 0 : _vm$popupItemIndexes3[0]]) !== null && _vm$targets3$_vm$popu2 !== void 0 && _vm$targets3$_vm$popu2.menusObject) {
          _vm.targets[_vm.popupMealTime][_vm.popupItemIndexes[0]].menusObject.photoUrl = (menu === null || menu === void 0 ? void 0 : menu.photoUrl) || '';
          _vm.targets[_vm.popupMealTime][_vm.popupItemIndexes[0]].menusObject.mealGlossariesId = (menu === null || menu === void 0 ? void 0 : menu.id) || '';
        }

        _vm.popupIsOpen = false;
        _vm.popupMealTime = '';
      },
      "change:is-open": function changeIsOpen(open) {
        return _vm.popupIsOpen = open;
      },
      "change:filter-page": function changeFilterPage(page) {
        return _vm.popupFilter.page = page;
      },
      "change:filter-search": function changeFilterSearch(search) {
        _vm.popupFilter.page = 1;
        _vm.popupFilter.search = search;
      }
    }
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalEditMenuDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah ingin mengubah data menu duplikasi? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingEditMenuDuplicate
          },
          on: {
            "click": _vm.onCloseEditMenuDuplicate
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingEditMenuDuplicate
          },
          on: {
            "click": _vm.onSubmitEditMenuDuplicate
          }
        }, [_vm._v(" Ya ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }